.input-group .btn {
  height: 54px;
  border: 2px solid var(--ama-color-brand-primary) !important;
  background-color: var(--ama-color-brand-primary);
  color: var(--ama-color-background-white);
  font-weight: 400;
  font-size: 1em;
  font-family: var(--font-lato);
  border-radius: 27px;
  border-top-right-radius: 27px !important;
  border-bottom-right-radius: 27px !important;
  margin-right: 10px;
  position: absolute;
  z-index: 10;
  min-width: 160px;
}

.input-group .btn:hover {
  background-color: var(--ama-color-background-white);
  color: var(--ama-color-brand-primary);
}

.input-group input {
  height: 54px;
}

.margin-bottom {
  margin-bottom: 15px;
}

/* input[type=file].form-control::file-selector-button {
  display: none;
} */

.field .input-group .form-control {
  margin: 0;
}

input[type=file] {
  margin-bottom: 0px;
  padding-left: 0px;
  margin-left: 30px !important;
  border: 2px solid var(--ama-color-brand-primary) !important;
  border-radius: 0;
}