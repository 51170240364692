.container_error {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.container_error a {
    color: var(--ama-color-text-gray);
    text-decoration: none;
}

.container_error a span {
    padding-right: 10px;
}

.container_error a span:hover {
    text-decoration: underline;
}


/* DARK MODE */
.dark_mode-error,
.dark_mode-error a {
    color: var(--ama-color-background-white);
}