@import url("https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400;700&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Red+Hat+Display:ital,wght@0,300..900;1,300..900&display=swap");

:root {
  --primary: #333399;
  --success: #3a8241;
  --danger: #b82118;
  --white: #ffffff;
  --focus-button: #ffc107;
  --info: #e0e0e0;
  --background: #e4e4e4;
  --font-lato: "Lato", sans-serif;
  --font-red-hat: "Red Hat Display", sans-serif;

  --header-dark: #272777;
  --header-top-bar-dark-text: #b6dcf6;

  --primary-disabled: #707070;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: "Lato", sans-serif !important;
}

button {
  font-family: "Lato", sans-serif;
}

ul {
  list-style: none;
}

#ANDI508 #ANDI508-moduleMenu {
  position: relative !important;
}
