html, body {
    margin: 0;
    height: 100%;
}

div#root {
    height: 100%;
    display: flex;
    flex-direction: column;
}

.main {
    height: auto;
    background-color: var(--ama-color-background-light-darker);
    position: relative;
    padding-bottom: 4rem;
    display: flex;
    flex-grow: 1;
    flex-direction: column;
}